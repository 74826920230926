import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import './Root.scss';
import { getSystemSettingRequest } from '../../apis/index';

export default function Root() {
  const [title, setTitle] = useState('');

  useEffect(() => {
    let isMobile = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );

    getSystemSettingRequest().then((res) => {
      const { title, mobile_url } = res.user;
      setTitle(title);
      if (isMobile) {
        location.href = mobile_url;
      }
    });
  }, []);

  return (
    <div className='whole-container'>
      <div className='header'>
        <div className='container'>
          <div className='site-name'>
            <Link to={'/'}>{title}</Link>
          </div>
          <div className='nav'>
            <Link to={'/'}>首页</Link>
            <Link to={'article/list'}>文章</Link>
            <Link to={'project/list'}>项目</Link>
            <Link to={'read-record/list'}>精神食粮</Link>
            <Link to={'about'}>关于</Link>
          </div>
        </div>
      </div>
      <div className='main'>
        <div className='container'>
          <Outlet context={[title]} />
        </div>
      </div>
      <div className='footer'>
        <div className='container'>
          Made by mawing{' '}
          <a href='https://beian.miit.gov.cn/' target='_blank'>
            苏ICP备2023028662号-1
          </a>
        </div>
      </div>
    </div>
  );
}
