import { request } from "./request";

// 获取系统设置
export function getSystemSettingRequest() {
  return request({ url: "/system/getSystemInfo" });
}

// 首页获取最近更新
export function getLatestUpdateRequest(data) {
  return request({ url: "/mix/getLatestItem", data });
}

// 获取文章列表
export function getArticleListRequest(data) {
  return request({
    url: "/article/getArticleList",
    data,
  });
}

// 获取文章详情
export function getArticleDetailRequest(data) {
  return request({
    url: "/article/getArticleDetail",
    data,
  });
}

// 获取项目列表
export function getProjectListRequest(data) {
  return request({
    url: "/project/getProjectList",
    data,
  });
}

// 获取项目详情
export function getProjectDetailRequest(data) {
  return request({
    url: "/project/getProjectDetail",
    data,
  });
}

// 获取阅读记录列表
export function getReadRecordListRequest(data) {
  return request({
    url: "/readRecord/getReadRecordList",
    data,
  });
}

// 获取阅读记录详情
export function getReadRecordDetailRequest(data) {
  return request({
    url: "/readRecord/getReadRecordDetail",
    data,
  });
}
