import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Pagination, Spin } from 'antd';

import './Index.scss';
import { getLatestUpdateRequest } from '../../apis/index';
import dayjs from 'dayjs';

export default function Index() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryBody, setQueryBody] = useState({ pageSize: 10, pageNumber: 1 });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [title] = useOutletContext();

  useEffect(() => {
    getList();
  }, [queryBody]);

  useEffect(() => {
    document.title = '首页 - ' + title;
  }, [title]);

  const getList = () => {
    setLoading(true);

    getLatestUpdateRequest(queryBody).then((res) => {
      setList(res.list);
      setTotal(res.total);
      setLoading(false);
    });
  };

  const onPaginationChange = (current) => {
    setQueryBody({ ...queryBody, ...{ pageNumber: current } });
  };

  const gotoDetailPage = ({ type, _id }) => {
    switch (type) {
      case 'article':
        return navigate(`article/detail/${_id}`);
      case 'project':
        return navigate(`project/detail/${_id}`);
      case 'readRecord':
        return navigate(`read-record/detail/${_id}`);
      default:
        break;
    }
  };

  return (
    <div className='main-container'>
      <Spin spinning={loading}>
        {list.map((item) => {
          return (
            <div
              key={item._id}
              className='item'
              onClick={() => gotoDetailPage(item)}
            >
              <div>{item.title}</div>
              <div>{dayjs(item.updatedAt).format('YYYY/MM/DD')}</div>
            </div>
          );
        })}
      </Spin>

      <Pagination
        defaultCurrent={1}
        current={queryBody.pageNumber}
        pageSize={queryBody.pageSize}
        hideOnSinglePage={true}
        onChange={onPaginationChange}
        total={total}
        className='pagination'
      />
    </div>
  );
}
