import { Card, notification, Image } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";
import "../../dist/vue-quill.snow.prod.css";

import { getProjectDetailRequest } from "../../apis";
import "./ProjectDetail.scss";

export default function ReadRecordDetail() {
  const { id } = useParams();
  const [detail, setDetail] = useState({ screenshots_urls: [] });
  const navigate = useNavigate();
  const [title] = useOutletContext();

  useEffect(() => {
    getProjectDetailRequest({ id }).then((res) => {
      console.log(res);
      if (res.error) {
        notification.error({
          message: "阅读记录不存在，将跳转回首页",
        });
        return navigate("/");
      }
      setDetail(res.projectDetail);
    });
  }, []);

  useEffect(() => {
    document.title = "首页 - " + title;
  }, [title]);

  function createDescriptionHTML() {
    return { __html: detail.note };
  }

  return (
    <div className="detail-page">
      <Card className="detail-conainer">
        {detail.cover_url && (
          <img src={detail.cover_url} className="detail-cover"></img>
        )}
        <div className="title">{detail.title}</div>
        <div className="update-time">
          <ClockCircleOutlined />
          <span>{dayjs(detail.updatedAt).format("YYYY/MM/DD")}</span>
        </div>

        <div className="description-list">
          {detail.homepage_url && (
            <div className="item">
              <div className="label">项目主页</div>
              <div className="value">
                <a href={detail.homepage_url} target="_blank">
                  {detail.homepage_url}
                </a>
              </div>
            </div>
          )}

          {detail.github_url && (
            <div className="item">
              <div className="label">Github主页</div>
              <div className="value">
                <a href={detail.github_url} target="_blank">
                  {detail.github_url}
                </a>
              </div>
            </div>
          )}

          {detail.wechat_qrcode_url && (
            <div className="item">
              <div className="label">微信小程序</div>
              <div className="value">
                <Image src={detail.wechat_qrcode_url} height={50}></Image>
              </div>
            </div>
          )}

          {detail.screenshots_urls.length > 0 && (
            <div className="item">
              <div className="label">截图</div>
              <div className="value screenshots-value">
                <Image.PreviewGroup>
                  {detail.screenshots_urls.map((item, index) => (
                    <div className="screenshot-item" key={index}>
                      <Image height={100} src={item} />
                    </div>
                  ))}
                </Image.PreviewGroup>
              </div>
            </div>
          )}
        </div>

        <div
          className="ql-editor"
          dangerouslySetInnerHTML={createDescriptionHTML()}
        />
      </Card>
    </div>
  );
}
