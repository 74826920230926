import { Card, Descriptions, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

import { getArticleDetailRequest } from "../../apis";
import "./ArticleDetail.scss";

export default function ReadRecordDetail() {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const navigate = useNavigate();
  const [title] = useOutletContext();

  useEffect(() => {
    getArticleDetailRequest({ id }).then((res) => {
      if (res.error) {
        notification.error({
          message: "阅读记录不存在，将跳转回首页",
        });
        return navigate("/");
      }
      setDetail(res.articleDetail);
    });
  }, []);

  useEffect(() => {
    document.title = "首页 - " + title;
  }, [title]);

  function createDescriptionHTML() {
    return { __html: detail.content };
  }

  return (
    <div className="detail-page">
      <Card className="detail-conainer">
        {detail.cover_url && (
          <img src={detail.cover_url} className="detail-cover"></img>
        )}
        <div className="title">{detail.title}</div>
        <div className="update-time">
          <ClockCircleOutlined />
          <span>{dayjs(detail.updatedAt).format("YYYY/MM/DD")}</span>
        </div>

        <div
          className="ql-editor"
          dangerouslySetInnerHTML={createDescriptionHTML()}
        />
      </Card>
    </div>
  );
}
