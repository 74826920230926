import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from './views/Root/Root';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Index from './views/Index/Index';
import ArticleDetail from './views/Articles/ArticleDetail';
import ArticleList from './views/Articles/ArticleList';
import ProjectList from './views/projects/ProjectList';
import ProjectDetail from './views/projects/ProjectDetail';
import ReadRecordList from './views/ReadRecords/ReadRecordList';
import ReadRecordDetail from './views/ReadRecords/ReadRecordDetail';
import About from './views/About/About';
import { NotFound } from './views/NotFound';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { path: '*', element: <NotFound /> },
          { index: true, element: <Index /> },
          {
            path: 'article',
            children: [
              { path: 'list', element: <ArticleList /> },
              { path: 'detail/:id', element: <ArticleDetail /> },
            ],
          },
          {
            path: 'project',
            children: [
              { path: 'list', element: <ProjectList /> },
              {
                path: 'detail/:id',
                element: <ProjectDetail />,
              },
            ],
          },
          {
            path: 'read-record',
            children: [
              {
                path: 'list',
                element: <ReadRecordList />,
              },
              {
                path: 'detail/:id',
                element: <ReadRecordDetail />,
              },
            ],
          },
          {
            path: 'about',
            element: <About />,
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router}></RouterProvider>);
