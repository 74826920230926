import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { getSystemSettingRequest } from "../../apis";
import "../../dist/vue-quill.snow.prod.css";
import "./About.scss";

export default function About() {
  const [user, setUser] = useState({ SNSLinks: [] });
  const [title] = useOutletContext();

  useEffect(() => {
    getSystemSettingRequest().then((res) => {
      setUser(res.user);
    });
  }, []);

  useEffect(() => {
    document.title = "首页 - " + title;
  }, [title]);

  const showHTML = () => {
    const html = { _html: user.description };
    return <div dangerouslySetInnerHTML={html}></div>;
  };

  function createDescriptionHTML() {
    return { __html: user.description };
  }

  return (
    <div className="detail-page">
      <Card>
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={createDescriptionHTML()}
        />
        <div className="SNS-links">
          {user.SNSLinks.map((item, index) => {
            return (
              <a href={item.url} className="item" key={index} target="_blank">
                <img src={item.logo_url}></img>
                <div>{item.title}</div>
              </a>
            );
          })}
        </div>
      </Card>
    </div>
  );
}
