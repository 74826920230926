import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Pagination, Spin } from "antd";
import dayjs from "dayjs";

import { getReadRecordListRequest } from "../../apis/index";

export default function ArticleList() {
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [queryBody, setQueryBody] = useState({ pageSize: 10, pageNumber: 1 });
  const [loading, setLoading] = useState(true);
  const [title] = useOutletContext();

  useEffect(() => {
    getList();
  }, [queryBody]);

  useEffect(() => {
    document.title = "首页 - " + title;
  }, [title]);

  const getList = () => {
    setLoading(true);
    getReadRecordListRequest(queryBody).then((res) => {
      setList(res.list);
      setTotal(res.total);
      setLoading(false);
    });
  };

  const onPaginationChange = (current) => {
    setQueryBody({ ...queryBody, ...{ pageNumber: current } });
  };

  return (
    <div className="main-container">
      <Spin spinning={loading}>
        {list.map((item) => {
          return (
            <Link to={`/read-record/detail/${item._id}`} key={item._id}>
              <div className="item">
                <div>{item.title}</div>
                <div>{dayjs(item.updatedAt).format("YYYY/MM/DD")}</div>
              </div>
            </Link>
          );
        })}
      </Spin>

      <Pagination
        defaultCurrent={1}
        current={queryBody.pageNumber}
        pageSize={queryBody.pageSize}
        hideOnSinglePage={true}
        onChange={onPaginationChange}
        total={total}
        className="pagination"
      />
    </div>
  );
}
