import { Card, Descriptions, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

import { getReadRecordDetailRequest } from "../../apis";
import "../../dist/vue-quill.snow.prod.css";
import "./ReadRecordDetail.scss";

export default function ReadRecordDetail() {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const navigate = useNavigate();
  const [title] = useOutletContext();

  useEffect(() => {
    getReadRecordDetailRequest({ id }).then((res) => {
      console.log(res);
      if (res.error) {
        notification.error({
          message: "阅读记录不存在，将跳转回首页",
        });
        return navigate("/");
      }
      setDetail(res.readRecordDetail);
    });
  }, []);

  useEffect(() => {
    document.title = "首页 - " + title;
  }, [title]);

  function createDescriptionHTML() {
    return { __html: detail.note };
  }

  return (
    <div className="detail-page">
      <Card className="detail-conainer">
        {detail.cover_url && (
          <img src={detail.cover_url} className="detail-cover"></img>
        )}
        <div className="title">{detail.title}</div>
        <div className="update-time">
          <ClockCircleOutlined />
          <span>{dayjs(detail.updatedAt).format("YYYY/MM/DD")}</span>
        </div>

        <Descriptions title="" size="mini">
          <Descriptions.Item label="类别">{detail.category}</Descriptions.Item>
          <Descriptions.Item label="是否看完">
            {detail.isCompleted ? "是" : "否"}
          </Descriptions.Item>
          <Descriptions.Item label="是否推荐">
            {detail.isRecommended ? "是" : "否"}
          </Descriptions.Item>
          {detail.completedDate && (
            <Descriptions.Item label="完成时间">
              {dayjs(detail.completedDate).format("YYYY/MM/DD")}
            </Descriptions.Item>
          )}
        </Descriptions>

        <div
          className="ql-editor"
          dangerouslySetInnerHTML={createDescriptionHTML()}
        />
      </Card>
    </div>
  );
}
