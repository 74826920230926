import axios from "axios";
import { notification } from "antd";

const request = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:7789/api/v1/fe"
      : "https://api.blog.coderdusk.com/api/v1/fe",
  timeout: 5000,
  method: "post",
});

request.interceptors.response.use(
  (res) => {
    return res.data.data;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      notification.error({
        message: "网络请求错误，请稍后再试",
      });
      return;
    }
    const { data } = error.response;
    return data;
  }
);

export { request };
